import React, { lazy } from "react";
import Loadable from "../Components/Loading/Loadable";
import Aplikace from "../Pages/Aplikace";
//const Aplikace = Loadable(lazy(() => import("../Pages/Aplikace")));
const OchranaSoukromi = Loadable(
  lazy(() => import("../Pages/OchranaSoukromi"))
);
const ObchodniPodminky = Loadable(
  lazy(() => import("../Pages/ObchodniPodminky"))
);
const Kontakt = Loadable(lazy(() => import("../Pages/Kontakt")));

let baseRoutes = [
  {
    path: "/aplikace",
    element: <Aplikace />,
  },
  {
    path: "/podminky",
    element: <ObchodniPodminky />,
  },
  {
    path: "/soukromi",
    element: <OchranaSoukromi />,
  },
  {
    path: "/kontakt",
    element: <Kontakt />,
  },
];

const routes = [
  ...baseRoutes,
  {
    path: "*",
    element: <Aplikace />,
  },
];
export default routes;
