import { Box, Typography } from "@mui/material";
import React from "react";
import SpeedIcon from "@mui/icons-material/Speed";
import { Motion } from "./Motion";
import { NOT_SELECTABLE, baseBlue, baseRed } from "../../Design/theme";

export default function ArticleText({
  sxProps = {},
  sxButton = {},
  sxTitle = {}, // Mensi font u nekterych
  icon = <SpeedIcon />,
  image = null,
  blueText,
  title,
  text,
  buttonText,
  whiteText = false,
  children,
  onButtonClick,
}) {
  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  };

  function SmallIcon({ color }) {
    let editedIcon = React.cloneElement(icon, {
      style: {
        width: "20px",
        height: "20px",
        color: color ? color : baseBlue,
        marginRight: "5px",
      },
    });

    return editedIcon;
  }

  return (
    <Box
      sx={{
        ...boxStyle,
        pt: "20px",
        maxWidth: "340px",
        ...sxProps,
      }}
    >
      {image && (
        <Box sx={{ width: "100%", mb: "18px" }}>
          <img
            style={{ width: "100%", borderRadius: "15px" }}
            alt="Článek"
            src={image}
          />
        </Box>
      )}
      <Box
        sx={{
          ...boxStyle,
          height: "20px",
          display: "flex",
          flexDirection: "row",
          alignItems: "bottom",
          alignContent: "bottom",
        }}
      >
        <SmallIcon color={whiteText ? "white" : null} />
        <Box
          sx={{
            height: "100%",
            width: "100%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography
            variant="smallBlue"
            component="div"
            sx={[
              {
                width: "100%",
                fontSize: { xs: "15px", sm: "16px" },
                lineHeight: { xs: "14px", sm: "15px" },
                my: "auto",
              },
              whiteText && { color: "white" },
            ]}
          >
            {blueText || ""}
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="mainTitle"
        component="h2"
        sx={[
          {
            mb: "18px",
            mt: "18px",
            fontSize: { xs: "24px", sm: "26px" },
            lineHeight: { xs: "32px", sm: "34px" },
            ...sxTitle,
          },
          image && { fontSize: "26px", lineHeight: "32px" },
          whiteText && { color: "white" },
        ]}
      >
        {title || ""}
      </Typography>
      <Typography variant="mainText" sx={[{}, whiteText && { color: "white" }]}>
        {text || ""}
      </Typography>
      {buttonText && (
        <Motion>
          <Box
            sx={{
              mt: "28px",
              bgcolor: baseRed,
              height: "42px",
              width: "fit-content",
              borderRadius: "42px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              ...NOT_SELECTABLE,
              ...sxButton,
            }}
            onClick={() => {
              if (onButtonClick) {
                onButtonClick();
              }
            }}
          >
            <Typography
              sx={[{ mx: "25px" }, whiteText && { color: "white" }]}
              variant="buttonText"
            >
              {buttonText}
            </Typography>
          </Box>
        </Motion>
      )}
      {children && children}
    </Box>
  );
}
