import { Box, Typography } from "@mui/material";
import React from "react";
import { NOT_SELECTABLE, baseRed } from "../../Design/theme";
import { Motion } from "./Motion";
import LinkContainer from "./LinkContainer";

export default function TitleTextLayout({
  sxProps = {},
  sxButton = {},
  sxTitle = {}, // Mensi font u nekterych
  title,
  text,
  buttonText,
  onButtonClick,
  buttonUrl = null,
}) {
  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  };

  return (
    <Box
      sx={{
        ...boxStyle,
        pt: "20px",
        maxWidth: "400px",
        minWidth: { xs: "290px", sm: "300px" },
        ...sxProps,
      }}
    >
      <Typography
        component="h1"
        sx={{
          mt: "18px",
          fontFamily: "Gordita",
          fontWeight: "bold",
          fontSize: { xs: "32px", sm: "38px" },
          lineHeight: { xs: "42px", sm: "48px" },
          color: "white",
          textAlign: "left",
          ...sxTitle,
        }}
      >
        {title || ""}
      </Typography>
      <Typography
        sx={{
          maxWidth: "320px",
          color: "white",
          fontFamily: "Gordita",
          fontWeight: "bold",
          fontSize: { xs: "17px", sm: "18px" },
          textAlign: "left",
          mt: "40px",
          mb: "50px",
        }}
      >
        {text || ""}
      </Typography>
      {buttonText && (
        <LinkContainer url={buttonUrl}>
          <Motion>
            <Box
              sx={{
                bgcolor: baseRed,
                height: "52px",
                width: "fit-content",
                borderRadius: "42px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                ...NOT_SELECTABLE,
                ...sxButton,
              }}
              onClick={() => {
                if (onButtonClick) {
                  onButtonClick();
                }
              }}
            >
              <Typography
                sx={{ mx: "30px", fontSize: "14px" }}
                variant="buttonText"
              >
                {buttonText || ""}
              </Typography>
            </Box>
          </Motion>
        </LinkContainer>
      )}
    </Box>
  );
}
