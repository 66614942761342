export const baseBlue = "#0265F4";
export const darkBlue = "#001949";
export const baseRed = "#F54F64";
export const baseGrey = "#73757A";
export const baseWhite = "#FBFBFB";

export const baseShadow3 = "1px 2px 5px 0px rgba(115,117,122,0.3)";
export const baseShadow36 = "1px 2px 6px 0px rgba(115,117,122,0.3)";

export const baseShadow2 = "1px 2px 5px 0px rgba(115,117,122,0.2)";
export const baseShadow5 = "1px 2px 5px 0px rgba(115,117,122,0.5)";
export const baseShadow8 = "1px 2px 5px 0px rgba(115,117,122,0.8)";

export const baseBorder = "1px solid #D9D9D9";
export const blueBorder = `2px solid ${baseBlue}`;
export const errorBorder = `2px solid ${baseRed}`;

export const borderRadius = "10px";

export const zindex = {
  guideOverlay: 9998,
  menuOverlay: 9999,
  navbar: 10000,
  overlay: 10001,
  maxOverlay: 10020,
};

export const SCREEN_SIZE = 24;

export const VERTICAL_SPACE = { xs: "10px", sm: "50px", md: "50px" };
export const NAVBAR_HEIGHT = 50;
export const FOOTER_HEIGHT = 180; // px

export const TEXT_NOT_SELECTABLE = {
  WebkitUserSelect: "none",
  WebkitTouchCallout: "none",
  msTouchSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
};

export const HIGHLIGHT_REMOVED = {
  WebkitTapHighlightColor: "transparent",
};

export const NOT_SELECTABLE = {
  ...TEXT_NOT_SELECTABLE,
  ...HIGHLIGHT_REMOVED,
};

export const tableScrollbarBottom = {
  borderBottomLeftRadius: { xs: "6px", sm: "12px" },
  borderBottomRightRadius: { xs: "6px", sm: "12px" },
  "&::-webkit-scrollbar": {
    display: { xs: "block", sm: "none" },
    height: "6px",
  },
  "&::-webkit-scrollbar-track": {
    display: { xs: "block", sm: "none" },
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    display: { xs: "block", sm: "none" },
    backgroundColor: "rgba(0,0,0,0.3)",
    outline: "none",
    borderRadius: "16px",
    borderTopLeftRadius: { xs: "0px", sm: "12px" },
    borderTopRightRadius: { xs: "0px", sm: "12px" },
  },
};

const themeFile = () => {
  return {
    palette: {
      primary: {
        main: `${baseBlue}`,
        light: `${baseBlue}`,
        dark: `${darkBlue}`,
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff6333",
        main: "#ff3d00",
        dark: "#b22a00",
        contrastText: "#fff",
      },
      error: {
        light: baseRed,
        main: baseRed,
        dark: baseRed,
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            logo: "p",
            navbar: "p",
            navbarSmall: "p",
            title: "h1",
            subtitle: "h2",
            tableText: "p",
            tableHeader: "h3",
            inputTitle: "p",
            buttonText: "p",
            settingsButtonText: "p",
            groupButtonText: "p",
            floatingButton: "p",
            error: "p",
            /* SETTINGS TEXTX */
            settHeader: "h2",
            settHeaderSmall: "h3",
            settText: "p",
            /* SMALL NAVIGATION */
            navHeader: "h2",
            navText: "p",
            /* FOOTER */
            footerText: "p",
          },
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            textTransform: "none",

            ...(ownerState.variant === "logo" && {
              fontSize: "14px",
              //fontFamily: "Roboto",
              fontWeight: "bold",
              textTransform: "none",
              lineHeight: "16px",
              color: "white",
              textAlign: "left",
            }),
            ...(ownerState.variant === "navbarSmall" && {
              fontSize: "13px",
              fontFamily: "Rawline",
              fontWeight: 900,
              textTransform: "uppercase",
              lineHeight: "18px",
            }),
            ...(ownerState.variant === "navbar" && {
              fontSize: "13px",
              fontWeight: 700,
              textTransform: "capitalize",
              color: baseWhite,
            }),
            ...(ownerState.variant === "title" && {
              fontSize: "32px",
              fontWeight: 700,
              fontFamily: "Gordita",
              lineHeight: "38px",
              color: baseBlue,
            }),
            ...(ownerState.variant === "subtitle" && {
              fontSize: "14px",
              fontWeight: 700,
              fontFamily: "Gordita",
              lineHeight: "14px",
              color: darkBlue,
            }),
            ...(ownerState.variant === "tableText" && {
              fontSize: "14px",
              lineHeight: "15px",
              color: baseGrey,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              lineClamp: 2,
              WebkitBoxOrient: "vertical",
            }),
            ...(ownerState.variant === "tableHeader" && {
              fontSize: "14px",
              lineHeight: "15px",
              color: darkBlue,
              textAlign: "left",
            }),
            ...(ownerState.variant === "inputTitle" && {
              fontSize: "13px",
              fontWeight: 700,
              fontFamily: "Gordita",
              lineHeight: "13px",
              color: darkBlue,
            }),
            ...(ownerState.variant === "buttonText" && {
              fontSize: "17px",
              lineHeight: "17px",
              fontWeight: "bold",
              color: "white",
              width: "100%",
              alignSelf: "center",
              textTransform: "none",
            }),
            ...(ownerState.variant === "settingsButtonText" && {
              fontSize: "15px",
              lineHeight: "15px",
              fontWeight: "bold",
              width: "100%",
              alignSelf: "center",
              textTransform: "none",
            }),
            ...(ownerState.variant === "groupButtonText" && {
              fontSize: "15px",
              lineHeight: "15px",
              fontWeight: "bold",
              width: "100%",
              alignSelf: "center",
              textTransform: "none",
            }),
            ...(ownerState.variant === "floatingButton" && {
              fontSize: "17px",
              lineHeight: "17px",
              fontWeight: "bold",
              alignSelf: "center",
              textTransform: "none",
              color: "white",
            }),
            ...(ownerState.variant === "h1" && {
              fontSize: "22px",
            }),
            ...(ownerState.variant === "body1" && {
              fontSize: "13px",
            }),
            /* SETTINGS PAGE */
            ...(ownerState.variant === "settHeaderSmall" && {
              fontFamily: "Gordita",
              fontWeight: "bold",
              color: baseBlue,
              fontSize: "10px",
              lineHeight: "10px",
              width: "100%",
              textTransform: "none",
            }),
            ...(ownerState.variant === "settHeader" && {
              fontFamily: "Gordita",
              fontWeight: "bold",
              color: darkBlue,
              fontSize: "16px",
              lineHeight: "16px",
              width: "100%",
              textTransform: "none",
            }),
            ...(ownerState.variant === "settText" && {
              color: darkBlue,
              fontSize: "11px",
              lineHeight: "14px",
              width: "100%",
              textTransform: "none",
            }),
            ...(ownerState.variant === "navHeader" && {
              color: darkBlue,
              fontFamily: "Gordita",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "14px",
            }),
            ...(ownerState.variant === "navText" && {
              color: baseGrey,
              fontSize: "12px",
            }),
            ...(ownerState.variant === "footerText" && {
              color: "white",
              fontFamily: "Gordita",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "14px",
            }),
            ...(ownerState.variant === "error" && {
              color: baseRed,
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "14px",
            }),
            ...(ownerState.variant === "mainTitle" && {
              fontFamily: "Gordita",
              fontWeight: "bold",
              color: darkBlue,
              fontSize: { xs: "26px", sm: "26px" },
              lineHeight: { xs: "32px", sm: "34px" },
              textAlign: "left",
            }),
            ...(ownerState.variant === "mainText" && {
              color: baseGrey,
              fontSize: "14px",
              lineHeight: "22px",
              textAlign: "left",
              fontWeight: 500,
            }),
            ...(ownerState.variant === "buttonText" && {
              color: baseWhite,
              fontSize: "12px",
              lineHeight: "14px",
              fontWeight: "bold",
            }),
            ...(ownerState.variant === "smallBlue" && {
              fontFamily: "Gordita",
              fontWeight: "bold",
              color: baseBlue,
              textAlign: "left",
              display: "flex",
              alignContent: "center",
              fontSize: { xs: "15px", sm: "16px" },
              lineHeight: { xs: "16px", sm: "17px" },
              //alignSelf: "center",
            }),
          }),
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          //disableFocusRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({}),
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            padding: 0,
          }),
        },
      },
    },
    typography: {
      useNextVariants: true,
    },
  };
};
export default themeFile;
