import React, { lazy } from "react";
import "./App.css";
import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import ScrollUpComp from "./Resources/Components/Base/ScrollUpComp";
import { Route, Routes } from "react-router-dom";
import routes from "./Resources/Routes/routes";
import themeFile from "./Resources/Design/theme";

const Cookie = lazy(() => import("./Resources/Components/Cookies/Cookie"));

export const theme = createTheme(themeFile());

function App() {
  return (
    <>
      <CssBaseline />
      <Cookie />

      <Box
        className="App"
        sx={{
          backgroundColor: "#FBFBFB",
          minWidth: "300px",
          height: "100%",
          overflowY: "hidden",
          overflowX: { xs: "visible", sm: "hidden" },
        }}
      >
        <ScrollUpComp />
        <Routes>
          {routes.map((route, index) => (
            <Route
              exact
              path={route.path}
              key={"RouteCore" + index}
              element={
                <ThemeProvider theme={theme}>
                  <div style={{ minHeight: "100vh" }}>
                    <div style={{}}>{route.element}</div>
                  </div>
                </ThemeProvider>
              }
            />
          ))}
        </Routes>
      </Box>
    </>
  );
}

export default App;
