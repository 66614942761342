import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Link, redirect, useNavigate } from "react-router-dom";
import { browserHistory } from "react-router";
import BaseButtonPublic from "../Buttons/BaseButtonPublic";
import {
  HIGHLIGHT_REMOVED,
  NAVBAR_HEIGHT,
  TEXT_NOT_SELECTABLE,
  VERTICAL_SPACE,
  baseBlue,
  baseRed,
  darkBlue,
} from "../../Design/theme";
import Logo from "../../Design/Navbar/Logo.png";
import { useMediaQuery } from "@mui/material";
import { theme } from "../../../App";
import MobileDrawerMenu from "./MobileDrawer";
import LinkContainer from "../Base/LinkContainer";

export const navbarPaths = [
  { name: "Aplikace", path: "/aplikace" },
  { name: "Pro firmy", path: "/firmy" },
  { name: "Kontakt", path: "/kontakt" },
];

export function getMobileOperatingSystem() {
  if (!navigator && !window) {
    return null;
  }
  var userAgent = navigator.userAgent || window.opera;

  // Android detected
  if (/android/i.test(userAgent)) {
    return "https://play.google.com/store/apps/details?id=com.metastream.lustracexekucios";
  }

  // iOS detected
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "https://apps.apple.com/cz/app/id1562115849";
  }

  return null;
}

export default function Navbar({ references }) {
  const [state, setState] = React.useState(false);
  const [bgTransparent, setBgTransparent] = React.useState(false);
  const navigate = useNavigate();
  const sandwichMenu = useMediaQuery(theme.breakpoints.down("md"));

  const elevated = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window : undefined,
  });

  React.useEffect(() => {
    if (!state) {
      setTimeout(() => {
        setBgTransparent(false);
      }, 250);
    } else {
      setBgTransparent(true);
    }
  }, [state]);

  React.useEffect(() => {
    if (window && sandwichMenu && state) {
      window.onpopstate = (e) => {
        setState(false);
      };
    }
  });

  return (
    <header>
      <Box
        sx={{
          height: `fit-content`,
          width: "100%",
          bgcolor:
            elevated || bgTransparent ? "rgba(0, 25, 73,0.92)" : "transparent",
          position: "fixed",
          top: 0,
          zIndex: 10000,
          boxShadow:
            elevated || state ? "0px 2px 5px 0px rgba(0,0,0,0.2)" : "none",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            px: "0px !important",
            //zIndex: zindexPublic.navbar,
            height: `fit-content`,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: `${NAVBAR_HEIGHT}px`,
              py: "8px",
              px: VERTICAL_SPACE,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                mr: "auto",
              }}
            >
              <Box
                component={Link}
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "none",
                  p: "10px",
                  pl: "0px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                  ...HIGHLIGHT_REMOVED,
                }}
                onClick={() => {
                  navigate("/");
                }}
                to="/"
              >
                <img
                  alt="Lustrace Exekucí"
                  src={Logo}
                  style={{
                    maxHeight: "38px",
                    maxWidth: "38px",
                    height: "38px",
                    width: "38px",
                  }}
                />

                <Box
                  style={{
                    display: "flex",
                    height: "38px",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="logo"
                    sx={{
                      mt: "2px",
                      ml: "8px",
                      height: "100%",
                      color: "white",
                      height: "fit-content",
                      ...TEXT_NOT_SELECTABLE,
                    }}
                  >
                    Lustrace
                    <br />
                    Exekucí
                  </Typography>
                </Box>
              </Box>
              {!sandwichMenu && (
                <Box
                  sx={{
                    height: "26px",
                    ml: "15px",
                    mr: "20px",
                    width: elevated ? "1px" : "1px",
                    bgcolor: "white",
                  }}
                />
              )}

              {!sandwichMenu &&
                navbarPaths.map((nav, index) => (
                  <Box
                    key={"NavbarLink" + index}
                    component={Link}
                    sx={{
                      textDecoration: "none",
                      mx: "3px",
                      px: "10px",
                      py: "8px",
                      color: "white",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      console.log(nav);
                      if (index == 2) {
                        navigate("/kontakt");
                      }
                      if (!references) {
                        navigate("/");
                      } else {
                        references[index].current.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {nav.name}
                    </Typography>
                  </Box>
                ))}
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                ml: "auto",
              }}
            >
              <LinkContainer url={getMobileOperatingSystem()}>
                <BaseButtonPublic
                  text="Stáhnout aplikaci"
                  color={elevated || state ? baseRed : darkBlue}
                  sxProps={{ height: "38px" }}
                  sxText={{}}
                  onClick={() => {
                    if (getMobileOperatingSystem()) {
                      return;
                    }
                    if (!references) {
                      navigate("/");
                    } else {
                      console.log(references);
                      references[0].current.scrollIntoView({
                        behavior: "smooth",
                      });
                      if (state) {
                        setState(false);
                      }
                    }
                  }}
                />
              </LinkContainer>
            </Box>
            {sandwichMenu && (
              <Box sx={{ ml: "10px" }}>
                <MobileDrawerMenu
                  references={references}
                  state={state}
                  setState={setState}
                />
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </header>
  );
}
