import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { withRouter } from "../../Routes/withRouter";
import PaymentLogos from "./PaymentLogos";
import LockIcon from "@mui/icons-material/Lock";
import { theme } from "../../../App";
import { Motion } from "./Motion";
import {
  HIGHLIGHT_REMOVED,
  NOT_SELECTABLE,
  TEXT_NOT_SELECTABLE,
  baseBlue,
} from "../../Design/theme";
import Logo from "../../Design/Navbar/Logo.png";

/*const Logo = () => {
  const editedIcon = React.cloneElement(<LogoSvg />, {
    style: {
      height: "100%",
      maxWidth: "33px",
      fill: baseBlue,
    },
  });
  return <>{editedIcon}</>;
};*/

const Lock = () => {
  const editedIcon = React.cloneElement(<LockIcon />, {
    style: {
      height: "12px",
      maxWidth: "12px",
      fill: "white",
    },
  });
  return <>{editedIcon}</>;
};

function Footer() {
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  let navigate = useNavigate();

  return (
    <footer>
      <Box
        sx={{
          width: "100%",
          height: `${180}px`,
          bgcolor: "black",
          position: "static",
          bottom: 0,
          //boxShadow: "0px -2px 5px 0px rgba(115,117,122,0.3)",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              width: "100%",
              height: "100%",
              p: "20px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box
                component={Link}
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "none",
                  p: "10px",
                  pl: "0px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                  ...HIGHLIGHT_REMOVED,
                }}
                onClick={() => {
                  navigate("/");
                }}
                to="/"
              >
                <img
                  alt="Lustrace Exekucí"
                  src={Logo}
                  style={{
                    maxHeight: "40px",
                    maxWidth: "40px",
                    height: "40px",
                    width: "40px",
                  }}
                />

                <Box
                  style={{
                    display: "flex",
                    height: "40px",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="logo"
                    sx={{
                      ml: "8px",
                      height: "100%",
                      height: "fit-content",
                      ...TEXT_NOT_SELECTABLE,
                    }}
                  >
                    Lustrace
                    <br />
                    Exekucí
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ ml: "auto" }}>
                <Motion>
                  <Box
                    component="div"
                    sx={{
                      textDecoration: "none",
                      width: "fit-content",
                      height: "22px",
                      bgcolor: baseBlue,
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                      borderRadius: "12px",
                      p: "4px",
                      cursor: "pointer",
                      WebkitTapHighlightColor: "transparent",
                    }}
                    onClick={() => {
                      navigate("/soukromi");
                    }}
                  >
                    <a
                      style={{
                        textDecoration: "none",
                        color: "white",
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                      href="/soukromi"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      aria-label="Zásady ochrany soukromí"
                    >
                      <Lock />
                      {!mobile && (
                        <Typography
                          sx={{
                            mx: "5px",
                            fontSize: "11px",
                            mt: "1px",
                            color: "white",
                            ...NOT_SELECTABLE,
                          }}
                        >
                          Zásady ochrany soukromí
                        </Typography>
                      )}
                    </a>
                  </Box>
                </Motion>
              </Box>
            </Box>
            <Box
              sx={{
                mt: "30px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  //display: { xs: "none", sm: "flex", md: "flex" },
                }}
              >
                <Typography
                  variant="footerText"
                  sx={{
                    fontWeight: "normal",
                    fontSize: "10px",
                    lineHeight: "15px",
                    textAlign: "left",
                    mr: "20px",
                  }}
                >
                  {mobile ? (
                    <>© {new Date().getFullYear()} Metastream&nbsp;s.r.o</>
                  ) : (
                    <>
                      © {new Date().getFullYear()} Metastream s.r.o |{" "}
                      <a
                        style={{ textDecoration: "none", color: "white" }}
                        href="mailto: metastream@email.cz"
                      >
                        metastream@email.cz
                      </a>{" "}
                      | Högrova 2862/2 | Královo Pole | 612 00 Brno | IČO:
                      08393494 | DIČ: CZ08393494 |{" "}
                      <a
                        style={{ textDecoration: "none", color: "white" }}
                        href="/podminky"
                        aria-label="Obchodní podmínky"
                      >
                        Obchodní podmínky{" "}
                      </a>
                    </>
                  )}
                </Typography>
              </Box>
              <Box sx={{ ml: "auto" }}>
                <PaymentLogos />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </footer>
  );
}

export default withRouter(Footer);
