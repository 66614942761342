import { Box, Container, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import svgBgTest from "../../Design/Aplikace/Svg/Background.svg";
import { NAVBAR_HEIGHT } from "../../Design/theme";

export default function BgLayout({
  image = svgBgTest,
  children = <></>,
  sxProps = {},
  sxContainer = {},
}) {
  return (
    <Container
      maxWidth="lg"
      sx={{
        px: "0px !important",
        //position: "relative",
        overflow: "visible",
        minHeight: "570px",
        ...sxContainer,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100vw",
          minHeight: "570px",
          left: 0,
          top: 0,
          //minWidth: { xs: "800px", sm: "1200px" },
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          ...sxProps,
        }}
      />
      <Box sx={{ position: "relative", pt: `${NAVBAR_HEIGHT}px` }}>
        {children}
      </Box>
    </Container>
  );
}
