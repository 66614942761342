import { Box, Button } from "@mui/material";
import React from "react";

export default function MobileMenuIcon(props) {
  const { open = false, sxProps = {} } = props;

  const otherProps = { ...props };
  delete otherProps.open;
  delete otherProps.sxProps;

  const lineStyle = {
    width: "100%",
    height: "2.5px",
    bgcolor: "white",
    borderRadius: "3px",
  };

  return (
    <Box
      sx={{
        width: "42px",
        height: "42px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        borderRadius: "20px",
        "&:hover": {
          cursor: "pointer",
        },
        ...sxProps,
      }}
      {...otherProps}
    >
      <Box
        sx={{
          width: "24px",
          height: open ? "24px" : "16px",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          justifyContent: "center",
          alignItems: "flex-end",
          //alignContent: "center",
        }}
      >
        <Box
          sx={{
            ...lineStyle,
            transition: "transform .3s ease-in-out",
            position: "absolute",
            top: 0,

            transform: open
              ? "rotate(-45deg) translate(-6px,9px);"
              : "rotate(0deg)",
          }}
        ></Box>
        <Box
          sx={{
            ...lineStyle,
            width: "60%",
            transition: "opacity .3s",
            opacity: open ? 0 : 1,
          }}
        ></Box>
        <Box
          sx={{
            ...lineStyle,
            position: "absolute",
            bottom: 0,
            width: open ? "100%" : "60%",
            transition: "transform .3s ease-in-out",
            transform: open
              ? "rotate(45deg) translate(-6px,-9px);"
              : "rotate(0deg)",
          }}
        />
      </Box>
    </Box>
  );
}
