import React from "react";
import { ReactComponent as Visa } from "../../Design/Payments/IconVisa.svg";
import { ReactComponent as Mastercard } from "../../Design/Payments/IconMC.svg";
import { ReactComponent as Gp } from "../../Design/Payments/IconGP.svg";
import { ReactComponent as Ap } from "../../Design/Payments/IconAP.svg";
import { Box } from "@mui/material";

const GooglePayIcon = () => {
  const editedIcon = React.cloneElement(<Gp />, {
    style: {
      maxHeight: "20px",
      width: "auto",
      maxWidth: "120px",
      marginRight: "6px",
    },
  });
  return <>{editedIcon}</>;
};

const ApplePayIcon = () => {
  const editedIcon = React.cloneElement(<Ap />, {
    style: {
      maxHeight: "20px",
      width: "auto",
      maxWidth: "120px",
      marginRight: "6px",
    },
  });
  return <>{editedIcon}</>;
};

const MastercardIcon = () => {
  const editedIcon = React.cloneElement(<Mastercard />, {
    style: {
      maxHeight: "16px",
      width: "auto",
      maxWidth: "120px",
    },
  });
  return <>{editedIcon}</>;
};

const VisaIcon = () => {
  const editedIcon = React.cloneElement(<Visa />, {
    style: {
      maxHeight: "13px",
      width: "auto",
      maxWidth: "120px",
      marginRight: "10px",
    },
  });
  return <>{editedIcon}</>;
};

export default function PaymentLogos() {
  return (
    <Box sx={{ display: "flex", alignItems: "center", alignContent: "center" }}>
      <ApplePayIcon />
      <GooglePayIcon />
      <VisaIcon />
      <MastercardIcon />
    </Box>
  );
}
