import React from "react";
import "./LoadingPage.css";
import { darkBlue } from "../../Design/theme";

export default function LoadingPage() {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        minHeight: "100vh",
        backgroundColor: darkBlue,
        opacity: 0.85,
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        zIndex: 12000,
        top: 0,
      }}
    >
      <div className="loader" />
    </div>
  );
}
