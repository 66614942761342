import React, { lazy, useRef } from "react";
import BgLayout from "../Components/Base/BgLayout";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import ContainerLayout from "../Components/Base/ContainerLayout";
import HeaderDeco from "../Design/Aplikace/HeaderDeco.webp";
import PhoneDeco from "../Design/Aplikace/PhoneDeco.webp";
import PersonDeco from "../Design/Aplikace/PersonDeco.webp";
import PhoneBlueDeco from "../Design/Aplikace/PhoneBlueDeco.webp";
import Paragraf from "../Design/Aplikace/Paragraf.webp";
import PhonesShop from "../Design/Aplikace/PhonesShop.webp";
import HeaderDecopng from "../Design/Aplikace/HeaderDeco.png";
import PhoneDecopng from "../Design/Aplikace/PhoneDeco.png";
import PersonDecopng from "../Design/Aplikace/PersonDeco.png";
import PhoneBlueDecopng from "../Design/Aplikace/PhoneBlueDeco.png";
import Paragrafpng from "../Design/Aplikace/Paragraf.png";
import PhonesShoppng from "../Design/Aplikace/PhonesShop.png";
import {
  HIGHLIGHT_REMOVED,
  NOT_SELECTABLE,
  VERTICAL_SPACE,
} from "../Design/theme";
import svgPhoneShop from "../Design/Aplikace/Svg/ShopsDeco.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as AppleIc } from "../Design/Payments/Stores/IconAppStore.svg";
import { ReactComponent as GoogleIc } from "../Design/Payments/Stores/IconGooglePlay.svg";
import { Motion } from "../Components/Base/Motion";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import PhoneAndroidRoundedIcon from "@mui/icons-material/PhoneAndroidRounded";
import { theme } from "../../App";

import { getMobileOperatingSystem } from "../Components/Navbar/Navbar";
import LinkContainer from "../Components/Base/LinkContainer";
import LoadableComp from "../Components/Loading/LoadableComp";

import ArticleText from "../Components/Base/ArticleText";
import Navbar from "../Components/Navbar/Navbar";
import TitleTextLayout from "../Components/Base/TitleTextLayout";
import Footer from "../Components/Base/Footer";

/*const ArticleText = 
  lazy(() => import("../Components/Base/ArticleText"));

const Navbar = lazy(() => import("../Components/Navbar/Navbar"));
const TitleTextLayout = lazy(() =>
  import("../Components/Base/TitleTextLayout")
);
const Footer = lazy(() => import("../Components/Base/Footer"));*/

function StoreIcon({ icon, sx }) {
  let editedIcon = React.cloneElement(icon, {
    style: {
      maxWidth: "130px",
      maxHeight: "40px",
      ...NOT_SELECTABLE,
      ...sx,
    },
  });

  return editedIcon;
}

export default function Aplikace() {
  const navigate = useNavigate();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const aplikaceRef = useRef(null);
  const firmyRef = useRef(null);
  const kontaktRef = useRef(null);

  return (
    <div
      style={{
        overflowX: "hidden",
        maxWidth: "100%",
        position: "relative",
        minWidth: "300px",
      }}
    >
      <Navbar references={[aplikaceRef, firmyRef, kontaktRef]} />
      {/********** HEADER **********/}
      <BgLayout
        sxContainer={{
          minHeight: { xs: "1000px", sm: "1000px", md: "570px" },
        }}
        sxProps={{
          minHeight: { xs: "1000px", sm: "1000px", md: "570px" },
          minWidth: { xs: "1300px", sm: "1000px", md: "auto" },
        }}
      >
        <Grid
          container
          spacing={2}
          columns={24}
          sx={{
            px: VERTICAL_SPACE,
            mt: { xs: "20px", sm: "40px", md: "40px" } /*position: "relative"*/,
          }}
        >
          <Grid item xs={24} sm={24} md={13} lg={13} sx={{}}>
            <TitleTextLayout
              title="Mobilní aplikace na kontrolu exekucí"
              text="S námi máte oficiální registr exekucí vždy po ruce"
              buttonText={"Stáhnout"}
              onButtonClick={() => {
                if (getMobileOperatingSystem()) {
                  return;
                }
                if (aplikaceRef) {
                  aplikaceRef.current.scrollIntoView({
                    behavior: "smooth",
                  });
                }
              }}
              buttonUrl={getMobileOperatingSystem()}
            />
          </Grid>
          <Grid
            item
            xs={24}
            sm={24}
            md={11}
            lg={11}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              //position: "relative",
            }}
          >
            <picture>
              <source srcSet={HeaderDeco} type="image/webp" />
              <Box
                component="img"
                alt="Ukázka"
                src={HeaderDecopng}
                sx={{
                  position: "absolute",
                  maxWidth: { xs: "560px", sm: "560px", md: "460px" },
                  height: "auto",
                  width: "auto",
                  right: { xs: "-30px", sm: "-30px", md: "-70px" },
                  bottom: {
                    xs: "-600px",
                    sm: "-600px",
                    md: "-122px",
                    lg: "-122px",
                  },
                }}
              />
            </picture>
          </Grid>
        </Grid>
      </BgLayout>
      {/********** ZISKEJTE UDAJE **********/}
      <Container
        maxWidth="lg"
        sx={{
          px: "0px !important",
          pt: { xs: "80px", sm: "140px" },
          position: "relative",
        }}
      >
        <Grid container spacing={2} columns={24} sx={{ px: VERTICAL_SPACE }}>
          <Grid item xs={24} sm={24} md={12} lg={12} sx={{}}>
            <ArticleText
              blueText={"Oficiální registr"}
              title={"Získejte údaje z oficiální evidence exekucí"}
              text={
                "Aplikace využívá oficiální evidenci exekucí, kterou vede Exekutorská komora. Získávání informací z této evidence je v souladu s vyhláškou Ministerstva spravedlnosti č. 329/2009 Sb. zpoplatněno."
              }
              buttonText={null}
              icon={<InfoRoundedIcon />}
            />
          </Grid>

          <Grid
            item
            xs={24}
            sm={24}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-end" },
              position: "relative",
              minHeight: { xs: "330px", sm: "400px" },
              mt: { xs: "30px", sm: "50px", md: "0px" },
            }}
          >
            <picture>
              <source srcSet={PhoneDeco} type="image/webp" />
              <Box
                component="img"
                alt="Ukázka"
                src={PhoneDecopng}
                sx={[
                  {
                    maxWidth: "550px",
                    position: "absolute",
                    height: "auto",
                    right: { md: "-160px", lg: "-100px" },
                  },
                  (mobile || tablet) && {
                    position: "relative",
                    mx: "auto",
                    left: 0,
                    minWidth: { xs: "360px", sm: "400px" },
                  },
                ]}
              />
            </picture>
          </Grid>
        </Grid>
      </Container>
      {/********** PROVERTE KOHOKOLIV **********/}
      <Container
        maxWidth="lg"
        sx={{
          px: "0px !important",
          pt: { xs: "80px", sm: "140px", md: "250px" },
          position: "relative",
        }}
      >
        <Grid
          container
          spacing={2}
          columns={24}
          sx={{ px: VERTICAL_SPACE, minHeight: "450px" }}
        >
          <Grid
            item
            xs={24}
            sm={24}
            md={12}
            lg={12}
            sx={{ mt: { xs: "0px", sm: "0px", md: "60px" } }}
          >
            <ArticleText
              blueText={"Lustrace"}
              title={"Prověřte kohokoliv"}
              text={
                "V aplikaci máte možnost kontrolovat každého. Firmy můžete kontrolovat podle IČO, k prověření fyzických osob vám postačí zadat rodné číslo nebo jméno, příjmení a datum narození."
              }
              buttonText={null}
              icon={<WarningRoundedIcon />}
            />
          </Grid>
          <Grid
            item
            xs={24}
            sm={24}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
              minHeight: { xs: "360px", sm: "450px" },
            }}
          >
            <picture>
              <source srcSet={PersonDeco} type="image/webp" />
              <Box
                component="img"
                alt="Ukázka"
                src={PersonDecopng}
                sx={{
                  position: "absolute",
                  right: "-80px",
                  maxWidth: { xs: "400px", sm: "520px", md: "480px" },
                  width: "auto",
                  bottom: { xs: "-60px", sm: "-60px", md: "12px" },
                }}
              />
            </picture>
          </Grid>
        </Grid>
      </Container>
      {/********** OKAMZITA KONTROLA **********/}
      <Container
        maxWidth="lg"
        sx={{
          px: "0px !important",
          pt: "140px",
          position: "relative",
        }}
      >
        <Grid container spacing={2} columns={24} sx={{ px: VERTICAL_SPACE }}>
          <Grid item xs={24} sm={24} md={12} lg={12} sx={{}}>
            <ArticleText
              blueText={"Rychlost"}
              title={"Okamžitá kontrola, okamžitý výsledek"}
              text={
                "V aplikaci můžete začít vyhledávat každého okamžitě. Není potřeba se registrovat, nemusíte čekat na žádné schválení přístupu k informaci, ani není třeba pro výsledek chodit na poštu."
              }
              buttonText={null}
            />
          </Grid>

          <Grid
            item
            xs={24}
            sm={24}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-end" },
              position: "relative",
              minHeight: { xs: "330px", sm: "500px" },
            }}
          >
            <picture>
              <source srcSet={PhoneBlueDeco} type="image/webp" />
              <Box
                component="img"
                alt="Ukázka"
                src={PhoneBlueDecopng}
                sx={[
                  {
                    maxWidth: "510px",
                    position: "absolute",
                    right: { md: "-130px", lg: "-60px" },
                  },
                  (mobile || tablet) && {
                    position: "relative",
                    mx: "auto",
                    left: 0,
                    minWidth: { xs: "360px", sm: "400px" },
                    width: "100%",
                    mt: "60px",
                  },
                ]}
              />
            </picture>
          </Grid>
        </Grid>
      </Container>
      {/********** ZJISTUJETE INFO PRAVIDELNE **********/}
      <div ref={firmyRef} />
      <Container
        maxWidth="lg"
        sx={{
          px: "0px !important",
          py: "120px",
          pt: { xs: "90px", sm: "120px", md: "150px" },
          position: "relative",
        }}
      >
        <Grid
          container
          spacing={2}
          columns={24}
          sx={{ px: VERTICAL_SPACE, minHeight: "550px" }}
        >
          <Grid item xs={24} sm={24} md={12} lg={12} sx={{}}>
            <ArticleText
              blueText={"Řešení pro firmy"}
              title={"Zjišťujete info o exekucích pravidelně?"}
              text={
                "Pokud v rámci své činnosti potřebujete zjišťovat informace o exekucích pravidelně, máme pro vás řešení. K tomuto účelu nabízíme privátní aplikaci Paragraf, která uspokojí potřeby malých i velkých týmů."
              }
              buttonText={"Více informací"}
              icon={<PeopleRoundedIcon />}
              onButtonClick={() => {
                if (window) {
                  window.open(
                    "https://vyhledavaniexekuci.cz/firmy",
                    "_blank",
                    "noreferrer"
                  );
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={24}
            sm={24}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
            }}
          >
            <picture>
              <source srcSet={Paragraf} type="image/webp" />
              <Box
                component="img"
                alt="Ukázka"
                src={Paragrafpng}
                sx={[
                  {
                    position: "absolute",
                    maxWidth: "800px",
                    width: "auto",
                    right: { md: "-430px", lg: "-290px" },
                  },
                  (mobile || tablet) && {
                    position: "relative",
                    mx: "auto",
                    left: 0,
                    minWidth: "280px",
                    width: "100%",
                    mt: { xs: "30px", sm: "60px" },
                  },
                ]}
              />
            </picture>
          </Grid>
        </Grid>
      </Container>
      {/********** APLIKACE PRO ANDROID I IOS **********/}
      <Box
        sx={{
          display: "flex",
          overflow: "hidden",
          maxWidth: "100%",
          position: "relative",
          pb: "-2px",
          mt: { xs: "0px", sm: "0px", md: "40px", lg: "40px" },
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            px: "0px !important",
            pt: "40px",
            mb: "160px",
          }}
        >
          <BgLayout
            image={svgPhoneShop}
            sxContainer={
              {
                /*minHeight: "740px"*/
              }
            }
            sxProps={{ minHeight: "101%", pb: "-2px", minWidth: "300px" }}
          >
            <div ref={aplikaceRef} />
            <Grid
              container
              spacing={2}
              columns={24}
              sx={{ px: VERTICAL_SPACE, pt: { xs: "25px", sm: "80px" } }}
            >
              <Grid
                item
                xs={24}
                sm={24}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <picture>
                  <source srcSet={PhonesShop} type="image/webp" />
                  <Box
                    component="img"
                    alt="Ukázka"
                    src={PhonesShoppng}
                    sx={{
                      minWidth: { xs: "340px", sm: "420px" },
                      maxWidth: "420px",
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </picture>
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    right: 30,
                    bottom: 80,
                  }}
                >
                  <LinkContainer
                    url={"https://apps.apple.com/cz/app/id1562115849"}
                  >
                    <Motion>
                      <Box
                        sx={{
                          cursor: "pointer",
                          width: "120px",
                          height: "40px",
                          ...NOT_SELECTABLE,
                        }}
                      >
                        <StoreIcon
                          sx={{ maxWidth: "120px" }}
                          icon={<AppleIc />}
                        />
                      </Box>
                    </Motion>
                  </LinkContainer>
                  <LinkContainer
                    url={
                      "https://play.google.com/store/apps/details?id=com.metastream.lustracexekucios"
                    }
                  >
                    <Motion>
                      <Box
                        sx={{
                          mt: "5px",
                          cursor: "pointer",
                          ...NOT_SELECTABLE,
                        }}
                      >
                        <StoreIcon icon={<GoogleIc />} />
                      </Box>
                    </Motion>
                  </LinkContainer>
                </Box>
              </Grid>
              <Grid
                item
                xs={24}
                sm={24}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  position: "relative",
                  mt: { xs: "60px", sm: "60px" },
                }}
              >
                <ArticleText
                  blueText={"Dostupnost"}
                  title={"Aplikace je dostupná pro Android a iOS"}
                  text={
                    "Aplikaci Vyhledávání exekucí si můžete stáhnout v mobilních obchodech Google Play a Appstore."
                  }
                  buttonText={null}
                  whiteText
                  icon={<PhoneAndroidRoundedIcon />}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      pt: "40px",
                    }}
                  >
                    <Typography
                      component={Link}
                      variant="mainText"
                      sx={{
                        color: "white",
                        textDecoration: "none",
                        width: "fit-content",
                        pb: "1px",
                        pt: "5px",
                        pr: "10px",
                        mb: "2px",
                        ...HIGHLIGHT_REMOVED,
                      }}
                      target="_blank"
                      to="https://apps.apple.com/cz/app/id1562115849"
                      href="https://apps.apple.com/cz/app/id1562115849"
                      aria-label="Stáhnout na iOS"
                    >
                      •{" "}
                      <span style={{ textDecoration: "underline" }}>
                        Stáhnout na iOS
                      </span>
                    </Typography>
                    <Typography
                      component={Link}
                      variant="mainText"
                      sx={{
                        color: "white",
                        textDecoration: "none",
                        width: "fit-content",
                        pt: "1px",
                        pb: "5px",
                        pr: "10px",
                        ...HIGHLIGHT_REMOVED,
                      }}
                      target="_blank"
                      to="https://play.google.com/store/apps/details?id=com.metastream.lustracexekucios"
                      href="https://play.google.com/store/apps/details?id=com.metastream.lustracexekucios"
                      aria-label="Stáhnout pro Android"
                    >
                      •{" "}
                      <span style={{ textDecoration: "underline" }}>
                        Stáhnout pro Android
                      </span>
                    </Typography>
                  </Box>
                </ArticleText>
              </Grid>
            </Grid>
          </BgLayout>
        </Container>
      </Box>
      <div ref={kontaktRef}>
        <Footer />
      </div>
    </div>
  );
}
