import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Divider, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { baseRed } from "../../Design/theme";
import BaseButtonPublic from "../Buttons/BaseButtonPublic";
import MobileMenuIcon from "../Base/MobileMenuIcon";
import { getMobileOperatingSystem } from "./Navbar";
import LinkContainer from "../Base/LinkContainer";

export default function MobileDrawerMenu({ state, setState, references }) {
  const navigate = useNavigate();
  const location = useLocation();

  function onFunctionChoose(index) {
    if (index == 0 || index == 1) {
      if (location?.pathname != "/aplikace") {
        navigate("/aplikace");
      } else {
        references[index].current.scrollIntoView();
      }
    } else if (index == 2) {
      navigate("/kontakt");
    } else if (index == 3) {
      navigate("/podminky");
    } else if (index == 4) {
      navigate("/soukromi");
    }
  }

  const navItems = [
    {
      name: "Aplikace",
    },
    {
      name: "Pro firmy",
    },
    {
      name: "Kontakt",
    },
    {
      name: "Obchodní podmínky",
    },
    {
      name: "Ochrana soukromí",
    },
  ];

  const toggleDrawer = (open) => (event) => {
    console.log("click");
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const list = () => (
    <Box
      sx={{ width: "auto", bgcolor: "white" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {navItems.map((data, index) => (
          <Box
            key={data.name ? data.name : "Empty"}
            onClick={() => {
              console.log(index);
              onFunctionChoose(index);
            }}
          >
            <ListItem
              sx={{
                mt: index == 0 ? "10px" : "0px",
                "&:hover": {
                  bgcolor: "rgba(200, 200, 200, .1)",
                },
              }}
              disablePadding
            >
              <ListItemButton sx={{ mt: index == 0 ? "46px" : "0px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: "10px",
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="navHeader" sx={{ mb: "10px" }}>
                        {data.name}
                      </Typography>
                    }
                  />
                </Box>
              </ListItemButton>
            </ListItem>
            {index !== navItems.length - 1 && (
              <ListItem key={"Divider" + index} disablePadding>
                <Box
                  sx={{ width: "100%", height: "1px", ml: "18px", my: "0px" }}
                >
                  <Divider />
                </Box>
              </ListItem>
            )}
          </Box>
        ))}
        <ListItem key={"SignoutListItem"} disablePadding>
          <Box
            key={"MenuItemProfileButton"}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              height: "80px",
              mt: "20px",
              mb: "10px",
            }}
          >
            <LinkContainer url={getMobileOperatingSystem()}>
              <BaseButtonPublic
                text={"Stáhnout aplikaci"}
                onClick={() => {
                  if (getMobileOperatingSystem()) {
                    return;
                  }
                  if (location?.pathname != "/aplikace") {
                    navigate("/aplikace");
                  } else {
                    references[0].current.scrollIntoView();
                  }
                }}
                sxProps={{
                  bgcolor: baseRed,
                  "&:hover": {
                    bgcolor: baseRed,
                  },
                  my: "0px",
                  alignItems: "center",
                  maxWidth: "240px",
                  height: "55px",
                }}
                sxText={{
                  alignSelf: "center",
                  textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "15px",
                  px: "20px",
                }}
              />
            </LinkContainer>
          </Box>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"TopDrawerMenu"}>
        {/*<IconButton
          icon={state ? <MenuOpenIcon /> : <MenuIcon />}
          iconProps={{ fill: `white` }}
          sxProps={{
            width: "42px",
            height: "42px",
          }}
          onClick={() => {
            setState((prev) => !prev);
          }}
        />*/}
        <MobileMenuIcon
          open={state}
          onClick={() => {
            setState((prev) => !prev);
          }}
        />
        <SwipeableDrawer
          anchor={"top"}
          open={state}
          disableScrollLock={true}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          BackdropProps={{ invisible: true }}
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
            zIndex: 9999,
          }}
          disableSwipeToOpen
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
